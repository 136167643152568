<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">

      <!-- Cliente -->
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <advanced-search
                rootClassName="col-sm-8 row"
                @onSelect="setMailType"
                :value="task.mailType"
                name="task.mailType"
                :clearable="false"
                :options="mailTypeList"
                v-bind:fetchOptions="mailTypeFetchOptions"
                title="Tipo">
              </advanced-search>
              <custom-switch
                :listItems="[{text: 'Teste', value: 1}]"
                v-model="task.isTestSend"
                name="task.status"
                type="checkbox"
                stateClassName="p-primary"
                :error="errors.first('task.is_test_send')">
              </custom-switch>
              <custom-switch
                :listItems="[{text: 'Disparos', value: 1}]"
                v-model="task.status"
                name="task.status"
                type="checkbox"
                stateClassName="p-primary"
                :error="errors.first('task.status')">
              </custom-switch>
            </div>
          </div>
        </div>
      </div>

      <!-- Descrição -->
      <div class="col-8 col-md-8">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('schedules.description') }}</h5>
            <form action="javascript:;">
              <div class="row">
                <div class="col-12">
                <custom-textarea
                  v-model="task.description"
                  name="description"
                  type="text"
                  rootClassName="md-form mb-12"
                  inputClassName="md-form-control"
                  :rows="5"
                  v-validate="{ required: false }"
                  :error="errors.first('task.description')"
                  background="#fff">
                </custom-textarea>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Frequência -->
      <div class="col-4 col-md-4">
        <div class="card card-fullheight">
          <div class="card-body">
            <h5 class="box-title mb-3">{{ $t('schedules.frequency') }}</h5>
            <form action="javascript:;">
              <div class="row">
                <div class="col-12 col-sm-4 form-group mb-6">
                  <label>Enviar a cada</label>
                  <input class="md-form-control" v-model="task.period"  type="number"  placeholder="1">
                </div>
                <div class="col-12 col-sm-5 form-group mb-6">
                  <label>&nbsp;</label>
                  <v-select label="name"  :clearable="false" :searchable="false" v-model="task.recurrence"  :options="recurrenceList" @input="onSelectRecurrence"  >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-sm-3 form-group mb-3">
                  <label>às</label>
                  <flat-pickr :config="configs.timePicker" v-model="task.beginTime" class="md-form-control"></flat-pickr>
                </div>
                <div class="col-12 col-sm-3 form-group mb-3" v-show="showMonthDayConfig">
                  <label>No Dia</label>
                  <input name="task.taskFrequency.monthday" class="md-form-control" v-model="task.monthday"  type="text"
                         v-validate="{ required: true, min_value: 1, max_value: 31 }"
                         :error="errors.first('task.taskFrequency.monthday')" >
                  <transition name="slide" mode="">
                    <small v-if="errors.first('task.taskFrequency.monthday')" class="royalc-error-field">{{ errors.first('task.taskFrequency.monthday') }}</small>
                  </transition>
                </div>

                <div v-if="showWeekdayConfig"  class="col-sm-12 form-group mb-12">
                  <label>Repetir</label>
                  <multiple-toggle :options="weekDayList"></multiple-toggle>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Responsavel -->
      <div class="col-12 col-md-4">
        <div class="card card-fullheight">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mt-2 mb-4">
                <label>{{$t('mails.template.sender')}}</label>
                  <v-select label="email"
                    name="task.sender"
                    class="md-form-control"
                    :clearable="true"
                    :searchable="true"
                    v-model="task.sender"
                    :options="ccContactList"
                    v-validate="{ required: true }
                  ">
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.email }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.email }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small
                    v-if="errors.first('task.sender')"
                    class="royalc-error-field">{{ errors.first('task.sender') }}
                  </small>
                </transition>
                <div class="font-11">
                  Não esqueça de preencher o CCO, se necessário receber a cópia dos e-mails.<br/>
                </div>
              </div>
              <!-- Default Sender-->
              <div class="col-md-12 mt-2 mb-4" v-show="task.sender && task.sender.id === 'vendedor'">
                <label>{{$t('mails.template.defaultSender')}}</label>
                  <v-select
                    label="email"
                    name="task.defaultSender"
                    class="md-form-control"
                    :clearable="true"
                    :searchable="true"
                    v-model="task.defaultSender"
                    :options="defaulSenderList"
                    v-validate="{ required: (task.sender && task.sender.id === 'vendedor') }
                  ">
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.email }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.email }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small
                    v-if="errors.first('task.defaultSender')"
                    class="royalc-error-field">{{ errors.first('task.defaultSender') }}
                  </small>
                </transition>
                <div class="font-11">
                  Remetente utilizado quando Cliente:<br/>
                  - Não possuir nenhum vendedor vinculado;<br/>
                  - Vendedor vinculado for igual à "Alan" ou<br/>
                  - Vendedor vinculado estiver inativo.<br/>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Com cópia -->
      <div class="col-12 col-md-4">
        <div class="card card-fullheight">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mt-2 mb-4">
                <label>{{$t('mails.template.cc')}}</label>
                <multiselect
                  v-model="task.cc"
                  :multiple="true"
                  :max="50"
                  track-by="id"
                  label="name"
                  :allow-empty="true"
                  placeholder="Selecione uma ou mais opções"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :custom-label="mailCustomLabel"
                  :options="ccContactList"
                  :show-labels="false">
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.email }}</strong>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <strong>{{ option.email }}</strong>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Com cópia oculta -->
      <div class="col-12 col-md-4">
        <div class="card card-fullheight">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mt-2 mb-4">
                <label>{{$t('mails.template.cco')}}</label>
                <multiselect
                  v-model="task.cco"
                  :multiple="true"
                  :max="50"
                  track-by="id"
                  label="name"
                  :allow-empty="true"
                  placeholder="Selecione uma ou mais opções"
                  :close-on-select="false"
                  :clear-on-select="true"
                  :custom-label="mailCustomLabel"
                  :options="ccContactList"
                  :show-labels="false">
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.email }}</strong>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <strong>{{ option.email }}</strong>
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Submit -->
      <div class="col-12">
        <button type="button" class="btn btn btn-outline-light pull-right" @click="onSaveTask">
          Salvar
        </button>
        <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
          Cancelar
        </button>
      </div>

    </div>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'
import dayjs from 'dayjs'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Portuguese from '@/i18n/flatpickr/pt.js'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Form
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import MultipleToggle from '@/components/Forms/MultipleToggle.vue'
import AdvancedSearch from '@/components/Forms/AdvancedSearch.vue'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'

// Services
import MailService from '@/services/MailService'
import TaskService from '@/services/TaskService'

export default {
  name: 'AutomaticEmailForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('mails.title') + ' ' + this.$i18n.t('sidebar.mail-automatic') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: true,
      fullPage: true,
      // List of options to select on select input
      mailTypeList: [],
      recurrenceList: [],
      customerList: [],
      employeeList: [],
      originsDestinationsList: [],
      commoditiesList: [],
      transportCompanyList: [],
      ccContactList: [],
      weekDayList: [
        {
          'id': 1,
          'name': 'D',
          'title': 'Domingo',
          'active': false
        },
        {
          'id': 2,
          'name': 'S',
          'title': 'Segunda-feira',
          'active': false
        },
        {
          'id': 3,
          'name': 'T',
          'title': 'Terça-feira',
          'active': false
        },
        {
          'id': 4,
          'name': 'Q',
          'title': 'Quarta-feira',
          'active': false
        },
        {
          'id': 5,
          'name': 'Q',
          'title': 'Quinta-feira',
          'active': false
        },
        {
          'id': 6,
          'name': 'S',
          'title': 'Sexta-feira',
          'active': false
        },
        {
          'id': 7,
          'name': 'S',
          'title': 'Sábado',
          'active': false
        }
      ],
      contactList: [],
      equipmentList: [],
      tierList: [],
      strategyList: [],
      currencyList: [],
      configs: {
        timePicker: {
          wrap: true,
          enableTime: true,
          enableSeconds: false,
          noCalendar: true,
          time_24hr: true
        },
        datePicker: {
          wrap: true,
          enableTime: false,
          dateFormat: 'd/m/Y',
          minDate: 'today',
          locale: Portuguese // locale for this instance only
        }
      },
      //  Selected Values (Form)
      task: {
        cc: null,
        cco: null,
        sender: null,
        defaultSender: null,
        mailType: null,
        period: 1,
        recurrence: null,
        status: 1,
        isTestSend: null,
        beginTime: null,
        monthday: null
      },
      // Configuration to mount table of selected values
      originsDestinationsFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'name',
          width: '90%',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      transportCompanyFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('transport_company.name'),
          sortField: 'nome',
          width: '90%',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      commoditiesFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'name',
          width: '90%',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      contactFields: [
        {
          name: 'idpessoa_contato',
          title: '#',
          sortField: 'idpessoa_contato',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'nome',

          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nickname',
          title: this.$i18n.t('origin_destination.nickname'),
          sortField: 'nickname',

          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'email',
          title: this.$i18n.t('origin_destination.email'),
          sortField: 'email',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      equipmentFields: [
        {
          name: 'description',
          title: this.$i18n.t('equipment.description'),
          sortField: 'description',

          formatter: (value) => this.$util.formatEmpty(value)
        }
      ]
    }
  },
  components: {
    Loading,
    MultipleToggle,
    AdvancedSearch,
    vSelect,
    CustomSwitch,
    CustomTextarea,
    Multiselect,
    flatPickr
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    // this.$store.dispatch('SetShowFilter', true)
    // this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    TaskService.getTaskRecurrenceList().then(res => {
      _this.recurrenceList = res.data.data
      // Set Default
      _this.task.recurrence = _this.recurrenceList[1]
      MailService.getMailTypes().then(res => {
        _this.mailTypeList = res.data.data
        MailService.getContacts({}, 1, 1000).then(res => {
          _this.ccContactList = res.data.data

          let automaticMailId = this.$route.params.automatic_mail_id

          if (automaticMailId) {
            MailService.getAutomaticEmail(automaticMailId).then(task => {
              // MailType
              _this.task.mailType = _this.mailTypeList.find((x) => {
                return x.id === task.data.mail_type
              })

              // Descriptiom
              _this.task.description = task.data.description

              // Copias
              _this.task.cc = task.data.cc
              _this.task.cco = task.data.cco

              // Frequency
              _this.task.period = task.data.task_frequency.period
              _this.task.recurrence = _this.recurrenceList.find((x) => {
                return x.id === task.data.task_frequency.type
              })

              if (task.data.task_frequency.begin_time) {
                _this.task.beginTime = dayjs(task.data.task_frequency.begin_time).format('HH:mm')
              }

              _this.task.monthday = task.data.task_frequency.monthday

              _this.task.employee = task.data.created_by

              _this.task.defaultSender = task.data.default_sender

              // Status
              if (task.data.status === 1) {
                _this.task.status = 1
              } else {
                _this.task.status = 2
              }

              if (task.data.sender.hasOwnProperty('id') && task.data.sender.id !== null) {
                _this.task.sender = task.data.sender
              }

              _this.task.defaultSender = task.data.default_sender

              // Is Test Send
              if (task.data.is_test_send) {
                _this.task.isTestSend = 1
              } else {
                _this.task.isTestSend = null
              }

              if (_this.task.recurrence.id === 2) {
                let selectedWeekDays = task.data.task_frequency.weekdays.split(',')

                for (var index in selectedWeekDays) {
                  let weekDay = parseInt(selectedWeekDays[index])

                  for (var j in _this.weekDayList) {
                    let item = this.weekDayList[j]
                    if (item.id === weekDay) {
                      item.active = true
                      this.$set(this.weekDayList, j, item)
                      break
                    }
                  }
                }
              }

              _this.isLoading = false
            }).catch(() => {
              _this.isLoading = false
            })
          } else {
            _this.isLoading = false
          }
        })
      })
    })
  },
  computed: {
    showMonthDayConfig () {
      if (this.task.recurrence && this.task.recurrence.id === 4) {
        return true
      }
      return false
    },
    showWeekdayConfig () {
      if (this.task.recurrence && this.task.recurrence.id === 2) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    defaulSenderList () {
      let ccList = this.ccContactList.filter((el) => {
        return el.id !== 'vendedor'
      })

      return ccList
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    isSuperAdmin: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  },
  methods: {
    setEmployee (value) {
      this.task.sender = value
    },
    mailCustomLabel ({ email }) {
      // eslint-disable-next-line camelcase
      return `${email}`
    },
    onCancel (e) {
      this.$router.push({ name: 'MailAutomaticIndex' })
    },
    showNumber (number, thousandsSep, defaultValue) {
      return util.showNumber(number, thousandsSep, defaultValue)
    },
    showCurrency (number, thousandsSep, defaultValue) {
      return util.numberFormat(number, 2, thousandsSep, defaultValue)
    },
    onSelectTier (val) {
      this.task.tier = val
    },
    onSelectRecurrence (val) {
      this.task.recurrence = val
    },
    onSelectStrategy (val) {
      if (val.id === 2) {
        this.task.showShipowner = null
      }

      this.task.strategy = val
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_schedule: object.id } }).href
    },
    setMailType (value) {
      this.task.mailType = value
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    mailTypeFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      MailService.getMailTypes(filters).then(res => {
        this.hasData = res.data.hasData
        this.mailTypeList = res.data.data
        loading(false)
      })
    },
    onSaveTask () {
      let _this = this

      _this.isLoading = true

      let taskData = {
        status: (parseInt(_this.task.status) === 1) ? 1 : 2,
        sender: _this.task.sender,
        cc: _this.task.cc,
        cco: _this.task.cco,
        defaultSender: _this.task.defaultSender,
        employee: _this.task.employee,
        mailType: _this.task.mailType.id,
        isTestSend: (parseInt(_this.task.isTestSend) === 1) ? 1 : 0,
        description: _this.task.description,
        taskFrequency: {
          type: _this.task.recurrence.id,
          weekdays: _this.weekDayList.filter(function (x) {
            return x.active === true
          }).map(x => (x.id)).join(','),
          period: _this.task.period,
          expiresIn: _this.task.expiresIn,
          expiresAt: _this.task.expiresAt,
          beginTime: _this.task.beginTime,
          monthday: _this.task.monthday
        }
      }

      let taskId = this.$route.params.automatic_mail_id

      if (taskId) {
        /*
        TaskService.getTask(taskId).then(task => {
          // Customer
          _this.task.customer = task.data.customer
          CustomerService.getCustomerTierLocal(this.task.customer).then(res => {
            this.tierLocal = res.data.customer
            // Origin and destination
            _this.task.originsDestinations = task.data.origins_destinations.map((x) => {
              let strategy = _this.strategyList.find((item) => {
                return item.id === x.approach
              })

              x.approach = strategy
              return x
            })

            // Commodities
            if (task.data.commodities.length > 0) {
              _this.task.commodities = []
              _this.task.commodities = task.data.commodities.map(x => ({
                id: x.commoditie.id,
                nome: x.commoditie.nome,
                status: (x.status)
              }))
            }

            // TransportCompanies
            if (task.data.shipowners.length > 0) {
              _this.task.shipowners = []
              _this.task.shipowners = task.data.shipowners.map(x => ({
                id: x.shipowner.id,
                nome: x.shipowner.nome,
                status: (x.status)
              }))
            }

            // Contacts
            _this.task.contacts = task.data.recipients.map(x => ({
              id: x.contact.id,
              nome: x.contact.nome,
              email: x.contact.email,
              status: (x.status)
            }))

            // Load customer contacts
            CustomerService.getCustomerContacts(this.task.customer).then(res => {
              _this.contactList = res.data.data
            })

            _this.task.cc = task.data.cc
            _this.task.cco = task.data.cco

            if (task.data.sender.hasOwnProperty('id') && task.data.sender.id !== null) {
              _this.task.sender = task.data.sender
            }

            _this.task.defaultSender = task.data.default_sender

            // Approach
            _this.task.tier = task.data.tier
            _this.task.strategy = _this.strategyList.find((x) => {
              return x.id === task.data.approach
            })

            // Show Shipowner
            if (task.data.show_shipowner) {
              _this.task.showShipowner = 1
            }

            // Status
            if (task.data.status === 1) {
              _this.task.status = 1
            } else {
              _this.task.status = 2
            }

            _this.task.optionsPerOriginDestination = task.data.options_per_origin_destination

            // Valido por pelo menos
            _this.task.minimumValidity = task.data.minimum_validity

            // Equipment
            _this.task.equipments = task.data.equipments.map(x => ({
              id: x.equipment.id,
              description: x.equipment.description,
              status: (x.status)
            }))

            // Frequency
            _this.task.period = task.data.task_frequency.period
            _this.task.recurrence = _this.recurrenceList.find((x) => {
              return x.id === task.data.task_frequency.type
            })

            // Attach Format
            _this.task.attachFormat = _this.attachFormatList.find((x) => {
              return x.id === task.data.attach_format
            })

            if (_this.task.recurrence !== 1) {
              let selectedWeekDays = task.data.task_frequency.weekdays.split(',')

              for (var index in selectedWeekDays) {
                let weekDay = parseInt(selectedWeekDays[index])

                for (var j in _this.weekDayList) {
                  let item = this.weekDayList[j]
                  if (item.id === weekDay) {
                    item.active = true
                    this.$set(this.weekDayList, j, item)
                    break
                  }
                }
              }
            }
          })
        }).catch(() => {
          _this.isLoading = false
        })
        */
        MailService.editAutomaticEmail(taskId, taskData).then(response => {
          _this.$router.push({ name: 'MailAutomaticIndex' })
        }).catch(() => {
          _this.isLoading = false
        })
      } else {
        MailService.newAutomaticEmail(taskData).then(response => {
          _this.$router.push({ name: 'MailAutomaticIndex' })
        }).catch(() => {
          _this.isLoading = false
        })
      }
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }
</style>
